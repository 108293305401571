import React from 'react'
import { Link } from 'react-router-dom' 
const Curriculum = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li> Information</li>
     <li> Curriculum </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Examination System </h1>
         </div>
     </div>
        <div className="row">
            <div className="col-lg-12">
               <div className="msgbox">
                    <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/Curriculum.jpg" alt="" className="img-fluid"/>
              </div>
               <p>The School strictly follows the curriculum and syllabi laid down by CBSE, New Delhi. The School is following the textbooks published by NCERT, New Delhi supported by innovative curriculum as AWES policy on curriculum issued from time to time. The Medium of Instruction is English also along with usual subjects like History, Geography, Social Sciences, Mathematics, Physics, Chemistry, Biology, Physical Training, Computer Applications, Commerce, Economics, Environmental Sciences, Martial Arts, Fine Arts, and Hindustani Music etc. The School has a well-stocked Library with varied books of all subjects and fields along with best Magazines and Journals of repute. We also maintain well equipped Laboratories for Science, Language and Computer Science. Through various sports, games, activity clubs and encouragement to hobbies the latent potentialities are discovered, encouraged and fine-tuned. Character formation and sowing of spiritual seeds and ethical values are considered basic to School education and imparted to the students through Moral Science.</p>
              <h6>Examination System</h6>
              <p>Abiding by the regulations laid down by C.B.S.E., evaluation system from academic year 2021-22 will follow the new assessment structure. The assessment structure comprises of two terms -</p>
            
             <div className="term">
                  <div className="termbox">
                     <p>Term -1 </p>

                  </div>
                  <div className="termbox">
                     <p>Term -2 </p>

                  </div>
             </div>
          </div>
        </div>
        <div className="row tablebg">
           <div className="col-lg-6">
              <h6>Examination Schedule (I-IX, XI) </h6>
                  <div className="table-responsive maintable">
                     <table className="table">
                     <thead>
                     <tr>
                     <th>Examination  </th>
                     <th>Term  </th>
                     <th>Month </th>
                     </tr>
                     </thead>
                     <tbody>
                     <tr>
                     <td>Periodic Test-1</td>
                     <td>Term-1</td>
                     <td>May</td>
                     </tr>
                     <tr>
                     <td>Half Yearly</td>
                     <td>Term-1</td>
                     <td>September</td>
                     </tr>
                     <tr>
                     <td>Periodic test-2</td>
                     <td>Term-2</td>
                     <td>December</td>
                     </tr>
                     <tr>
                     <td>Annual Exam</td>
                     <td>Term-2</td>
                     <td>February</td>
                     </tr>
                     </tbody>
                     </table>
                     </div>
           </div>
            <div className="col-lg-6">
                <h6>Examination Schedule (X and XII)</h6>
                  <div className="table-responsive maintable">
                      <table className="table">
                         <thead>
                             <tr>
                                 <th>Examination </th>
                                 <th>Term </th>
                                 <th>Month </th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr>
                                 <td>Periodic Test-1</td>
                                 <td>Term-1</td>
                                 <td>May</td>
                             </tr>
                             <tr>
                                 <td>Half Yearly</td>
                                 <td>Term-1</td>
                                 <td>September</td>
                             </tr>
                             <tr>
                                 <td>Periodic test-2</td>
                                 <td>Term-2</td>
                                 <td>December</td>
                             </tr>
                             <tr>
                                 <td>Annual Exam</td>
                                 <td>Term-2</td>
                                 <td>February</td>
                             </tr>
                         </tbody>
                     </table>
                     </div>
           </div>
        </div>
</div>
</div>
    </>
  )
}

export default Curriculum
