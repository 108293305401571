import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { getSliders } from "../Service/Api";
function HomeSlider() {
  return (
    <div id="slider" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner carousel-slider">
        <div class="carousel-item active">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-1.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>
        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-2.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>
        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-3.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>
        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-4.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>

        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-5.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>

        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-6.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>
        <div class="carousel-item">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/sld-7.jpg" class="d-block w-100" alt="Sacred Heart Convent School, Jagraon" />
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#slider" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button>
      <button class="carousel-control-next" type="button" data-bs-target="#slider" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>
    </div>
  )
}

export default HomeSlider;
