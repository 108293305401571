import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { getHighlight } from '../Service/Api';
const Highlight = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const data = await getHighlight();
      console.log(data);
      setData(data);
    };
    getData();
  }, []);

  return (
    <>
     
        {data.length > 0 &&
                  data.map((item, index) => (
          <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank">{item.description} <i class="bi bi-paperclip"></i></Link>
                  ))}

          
    </>
  )
}

export default Highlight;
