import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { getPrincipalMessage } from '../Service/Api';

const MessageTwo = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getPrincipalMessage();
      setData(messages);
    };
    fetchData();
  }, []);

  const emptyArray = [
    { description: "Education is the most powerful and effective medium of social transformation and national integration. The Sacred Heart Convent School, Jagraon has made great contribution towards the accomplishment of this noble aim. Any educational institution stands for inculcating of education that is total and integral. The primary goal of an educational institution is the formation rather than academic studies and imparting information to the students. Every student needs to be helped individually in his/her efforts in self-formation, to play one’s role in life and to build a healthy society. This automatically leads to the progress of the persons, family society and nation. The end of education is to build character and to help to develop good citizens of tomorrow.Future of the nation depends on their knowledge, their character and their skills. Character building begins at home and continues in school. Education is a joint venture and it calls for concerted efforts and active participation of all concerned – the parents, teachers, students, educational agency and the state. The school and the home, teachers and parents are equal partners in this endeavor. May we the parents, teachers and the citizens at large accept the challenges and let our educational effort fulfill our essential mission for the youth and the nation. May the Sacred Heart of Jesus bless us all with His Love, Peace and Joy.", attachments: "principal.jpg" },   
  ];

  return (
    <>
      {data.length>0 ?data?.filter((div) => div.category === "Principal").map((item, index) => (
        <div key={index} className='row'>
          <div className="col-md-6 padding-0 arrow-img shuffleDiv">
            <div className="message-content shape-arrow">
              <h3>Principal's Message</h3>
              <p>{item.message}</p>
              <Link to="/PrincipalMessage">
                <div className="arrow">
                  <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/arrow-right.png" alt="Sacred Heart Convent School, Jagraon"/>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 padding-0">
            <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" />                    
          </div>
        </div>
      ))
      :emptyArray.map((data, index) => (
        <div key={index} className='row'>
          <div className="col-md-6 padding-0 arrow-img shuffleDiv">
            <div className="message-content shape-arrow">
              <h3>Principal's Message</h3>
              <p>{data.description}</p>
              <Link to="/PrincipalMessage">
                <div className="arrow">
                  <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/arrow-right.png" alt="Sacred Heart Convent School, Jagraon"/>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-6 padding-0">
            <img src={data.attachments} className="img-fluid" alt="Sacred Heart Convent School, Jagraon" />                    
          </div>
        </div>
      ))}
    </>
  );
};

export default MessageTwo;
