import React from 'react'
import { Link } from 'react-router-dom' 
const OurSchool = () => {
  return (
    <div>
    <div className="innerslide">
    <ul className="breadcrumb">
    <li><Link to="/home"> Home</Link> </li>
     <li>About Us</li>
     <li>Our School</li>
 </ul>
</div>
<div className="innersec">
<div className="container">
<div className="row">
<div className="col-lg-12">
 <h1>Our School </h1>
</div>
</div>
<div className="row">
<div className="col-lg-12">
 <p><b>The Sacred Heart Convent (Sr. Sec.) School</b>, Jagraon is a Christian Minority Educational Institution run by the sisters of the Congregation of the Sacred Heart of Jesus. The Society of Sacred Heart Congregation is registered under Society's Registration Act of <b>1985-86 vide Registration No.228</b>. It was established in 1990 in a rented building and later in 1991 shifted to the present building.</p>
         <p>Students are prepared for the All India Senior Secondary School Examination <b>(ClassName X-XII)</b> of the Central Board of Secondary Education <b>(CBSE)</b>, New Delhi.</p>
        <p>Admission is open to students of all caste, creed or social status and it holds up to them the sublime motto: <b> "Love, Wisdom and Service"</b>. We realize that the future of our nation depends on the moral strength of our youth and hence every effort is made to give our students a sound and all round education in keeping with the nation's aspirations and to make them morally,physically and mentally fit to become active, useful and worthy citizens of our dear motherland.</p>
       </div>
        <div className="col-lg-6">
          <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg.jpg" className="img-fluid aboutinnerimg" alt="The Sacred Heart Convent" />
        </div>
        <div className="col-lg-6">
           <img src="https://webapi.entab.info/api/image/SHSJ/public/Images/aboutimg1.jpg" className="img-fluid aboutinnerimg" alt="The Sacred Heart Convent" />
        </div>
      </div>
 </div> 
</div>
    </div>
  )
}

export default OurSchool
