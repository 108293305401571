import React, { useEffect, useState } from 'react'
import { getOnlineRegistration } from '../Service/Api';
import { Link } from 'react-router-dom'
const OnlineRegistration = () => {

  const [data, setData] = useState([]);

  useEffect(()=>{
    const getData =async ()=>{
      try {
        let registrationData = await getOnlineRegistration()
        setData(registrationData);

      } catch (error) {
        console.log(error)
      }
    }

    getData()
  },[])


  useEffect(() => {
    console.log("events_data", data);
}, [data]);


  return (
   <>
      <div style={{display:data.length>0?`block`:'none'}} className="blinker">
          <Link to="https://entab.online/shcjpb" target="_blank"> {data[0]?.title}  </Link>
          <Link to={`https://webapi.entab.info/api/image/${data[0]?.attachments[0]}`} target="_blank">  <i class="bi bi-paperclip"></i></Link>
       </div>
   </>
  )
}

export default OnlineRegistration
