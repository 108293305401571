import React from 'react'
import { Link } from 'react-router-dom' 
const AlumniRegistration = () => {
  return (
    <>
    <div className="alumni-register">
        <p><Link to="https://entab.online/shcjpb"  target="_blank">Alumni <span>Registration</span></Link></p>
        <p><Link to="/"><img src="https://webapi.entab.info/api/image/SHSJ/public/Images/interface.gif" alt="Sacred Heart Convent School, Jagraon"/></Link></p>
   </div>
    </>
  )
}

export default AlumniRegistration
